import { DateTime } from 'luxon';

export function getTime(timestamp: any) {
    const date = new Date(timestamp);
    return DateTime.fromObject(
        {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
        },
        { zone: 'UTC' }
    )
        .set({
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes(),
        })
        .setZone('Australia/Sydney')
        .toFormat('t');
}

export function getDate(timestamp: any) {
    return DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
        locale: 'en-AU',
    }).toFormat('DDDD');
}

export function getDateUtc(timestamp: any) {
    return DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
        locale: 'en-CA',
    })
        .setZone('UTC')
        .toFormat('D TT');
}

export function getTimeUtc(timestamp: any) {
    return DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
        locale: 'en-AU',
    }).toFormat('TT');
}

export function parseTime(timestamp: any) {
    return DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
        locale: 'en-AU',
    }).toFormat('t');
}

export function customDateValidation() {
    const today = DateTime.now().setZone('Australia/Sydney');

    const futureDate = DateTime.fromObject(
        {
            year: 2023,
            month: 11,
            day: 25,
        },
        { zone: 'Australia/Sydney' }
    );
    return today < futureDate;
}

export function isPassOrToday(timestamp: any) {
    const timestampDateTime = DateTime.fromISO(timestamp);

    return timestampDateTime <= DateTime.now();
}

export function isNextTwoWeeksFrom(timestamp: any, firstDate: any) {
    const date = DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
    });
    const currentDate = DateTime.fromISO(firstDate, {
        zone: 'Australia/Sydney',
    });

    return date >= currentDate && date <= currentDate.plus({ days: 13 });
}

export function isNextTwoWeeks(timestamp: any) {
    const date = DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
    });
    const currentDate = DateTime.now().setZone('Australia/Sydney');
    return date >= currentDate && date <= currentDate.plus({ weeks: 2 });
}

export function isMoreThanTwoWeeks(timestamp: any) {
    const date = DateTime.fromISO(timestamp, {
        zone: 'Australia/Sydney',
    });

    const currentDate = DateTime.now().setZone('Australia/Sydney');
    const twoWeeksLater = currentDate.plus({ weeks: 2 });

    return date > twoWeeksLater;
}

export function customDateAdd10Minutes(timestamp: any) {
    const today = DateTime.utc();

    return DateTime.fromISO(timestamp)
        .toUTC()
        .set({ day: today.day, hour: today.hour, minute: today.minute })
        .plus({ minutes: 10 })
        .toString();
}

export function customDateSet(timestamp: any) {
    return DateTime.fromISO(timestamp)
        .toUTC()
        .set({ hour: 8, minute: 0, second: 0 })
        .toISO();
}

export function isOvernight(timestamp: any) {
    return (
        DateTime.fromFormat(timestamp, 'yyyy-MM-dd HH:mm:ss', {
            zone: 'utc',
        })
            .setZone('Australia/Sydney')
            .toFormat('HH:mm:ss') === '00:00:00'
    );
}
