import CryptoJS from 'crypto-js';
import config from '@/utils/config';

const { env } = JSON.parse(config);

function islive() {
    if (env && env === 'production') {
        return true;
    }

    return false;
}

function getTotalPrice(cart: any) {
    let price = 0;
    if (cart?.package?.price) {
        price += cart.package.price;
    }
    if (cart?.addOns?.length > 0) {
        price += cart.addOns.reduce(
            (a: any, e: any) => a + e.price * e.quantity,
            0
        );
    }
    return price / 100;
}

function cleanItemsPayload(items: any) {
    const cleanItems = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
        const cleanItem: any = {};
        cleanItem.item_id = item.id;
        cleanItem.item_name = item.name;
        cleanItem.item_category = item?.category
            ? item?.category
            : item?.category?.name || 'Package';
        // eslint-disable-next-line no-nested-ternary
        cleanItem.price = item.price?.value
            ? item.price.value / 100
            : item.price
            ? item.price / 100
            : 0;
        cleanItem.quantity = item.quantity;

        cleanItems.push(cleanItem);
    }
    return cleanItems;
}

export function useTrackStartedSubscriptionProcess() {
    if (!islive()) return;

    window.dataLayer.push({
        event: 'StartedSubscriptionProcess',
    });
}

export function useTrackRemoveCart(item: any, count: number) {
    if (!islive()) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: 'AUD',
            value: item.price ? item.price / 100 : 0,
            items: [
                {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.category,
                    price: item.price ? (item.price * count) / 100 : 0,
                    quantity: count,
                },
            ],
        },
    });
}

export function useTrackViewItemList(items: any, itemType: string) {
    if (!islive()) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            item_list_id: itemType,
            item_list_name: itemType,
            items: cleanItemsPayload(items),
        },
    });
}

export function useTrackSelection(items: any, itemType: string) {
    if (!islive()) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            item_list_id: itemType,
            item_list_name: itemType,
            items: cleanItemsPayload(items),
        },
    });
    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            item_list_id: itemType,
            item_list_name: itemType,
            items: cleanItemsPayload(items),
        },
    });
}

export function useTrackInitiateCheckout(newUser: string | null, cart: any) {
    if (!islive()) return;
    const cartPayload = [cart.package, ...cart.addOns, ...cart.products];
    if (newUser) {
        window.dataLayer.push({
            event: 'InitiateCheckout',
        });
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: 'AUD',
            value: getTotalPrice(cart),
            items: cleanItemsPayload(cartPayload),
        },
    });
}

export function useImpactIdentify(id: string, email: string | null) {
    if (!islive()) return;
    window.ire('identify', {
        customerId: id,
        customerEmail: email ? CryptoJS.SHA1(email as any).toString() : '',
    });
}

export function useTrackPurchase(
    email: string | null,
    totalPrice: number | null,
    purchaseId: string,
    userId: string,
    custStatus: string,
    cart: any,
    promoCode: string | null,
    discount: any
) {
    if (!islive()) return;
    const cartPayload = [cart.package, ...cart.addOns, ...cart.products];
    const cleanItems = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of cartPayload) {
        // Impact
        item.category = item?.category?.name || 'Package';
        item.subTotal = ((item.price * item.quantity) / 100).toFixed(2);
        item.sku = item.sku || '';
        // G4
        item.item_id = item.id;
        item.item_name = item.name;
        item.item_category = item?.category?.name || 'Package';
        item.discount = item.discounted;
        item.price = item.price ? item.price / 100 : 0;

        const {
            id,
            credited,
            discounted,
            paid,
            thumbnail,
            parents,
            selection,
            ...cleanItem
        } = item;
        cleanItems.push(cleanItem);
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'Purchase',
        ecomm_mail: email,
        totalprice: totalPrice,
        ecommerce: {
            transaction_id: purchaseId,
            value: totalPrice,
            tax: 0,
            shipping: 0,
            currency: 'AUD',
            coupon: promoCode === '' ? null : promoCode,
            items: cleanItems,
        },
    });

    window.ire(
        'trackConversion',
        36569,
        {
            orderId: purchaseId,
            customerId: userId,
            customerEmail: CryptoJS.SHA1(email as any).toString(),
            customerStatus: custStatus,
            currencyCode: 'AUD',
            orderPromoCode: promoCode === '' ? null : promoCode,
            // eslint-disable-next-line no-nested-ternary
            orderDiscount: discount?.values?.[0]?.value
                ? discount?.values?.[0]?.type === 'value'
                    ? (discount?.values?.[0]?.value as number) / 100
                    : (
                          (totalPrice! *
                              (discount?.values?.[0]?.value as number)) /
                          100 /
                          100
                      ).toFixed(2)
                : null,
            items: cleanItems,
        },
        {
            verifySiteDefinitionMatch: true,
        }
    );
}
