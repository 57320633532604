import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUnauthenticated,
    selectUnexpectedError,
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import Button from '../common/Button';
import AlertModal from './AlertModal';

export default function RedirectAfterErrorModal() {
    const [, setOpen] = useState(false);
    const dispatch = useDispatch();
    const unauthenticated = useSelector(selectUnauthenticated);
    const unexpectedError = useSelector(selectUnexpectedError);

    return (
        <AlertModal
            open={unauthenticated || unexpectedError}
            onClose={setOpen}
            title={
                unauthenticated
                    ? 'Your session has expired. Please refresh and try again.'
                    : 'An unexpected error has occurred. Please try again later.'
            }
        >
            <div className="mt-8 grid grid-cols-2 gap-2">
                {unauthenticated && (
                    <Button
                        type="button"
                        className="flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                        onClick={() => {
                            dispatch(setUnauthenticated(false));
                            window.location.reload();
                        }}
                    >
                        Ok
                    </Button>
                )}
                {unexpectedError && (
                    <Button
                        type="button"
                        className="flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                        onClick={() => {
                            dispatch(setUnexpectedError(false));
                            setOpen(false);
                        }}
                    >
                        Ok
                    </Button>
                )}
            </div>
        </AlertModal>
    );
}
